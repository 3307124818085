ul {
  padding-left: 0px;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0px;
}
