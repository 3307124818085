.login-module {
    background-color: #f5f5fa;
    // background: url("../../assets/login-image1.jpg") no-repeat center center fixed;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    // box-sizing:border-box;
    // background-image:url('../../images/bg-image.png') ;
    width: 100%;
    height: 100vh;
    .login-holder {
        background: #ffffff;
        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        // height: 480px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 35px 45px;
        padding-bottom: 12px;
        width: 370px;
        .login-box {
            .login-tabs {
                margin-top: 10px;
                .nav {
                    .nav-item {
                        width: 48%;
                        cursor: pointer;
                        &:nth-child(2) {
                            padding-left: 10px;
                        }
                        .nav-link {
                            color: #000000;
                            border-radius: 0px;
                            border: 1px solid #e9e8f2;
                            &:hover {
                                border-color: rgb(139, 125, 242);
                            }
                        }
                        .nav-link.active {
                            color: rgb(139, 125, 242);
                            border-color: rgb(139, 125, 242);
                        }
                    }
                }
            }
            .form-group {
                small {
                    position: relative;
                    top: 4px;
                    display: block;
                    color: red;
                    font-size: 14px;
                    text-align: left;
                }
                button {
                    border-radius: 0px;
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    color: #535259;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 48px;
                }
                label {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                    color: #535259;
                }
                input {
                    height: 48px;
                    border-radius: 0px;
                    border: 1px solid #b2b0bf;
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    color: #535259;
                    border: 1px solid #8b7df2;
                    &:focus {
                        border: 1px solid #8b7df2;
                        box-shadow: none;
                    }
                }
            }
            .has-search {
                position: relative;
                .form-control-feedback {
                    position: absolute;
                    z-index: 5;
                    display: block;
                    width: 2.375rem;
                    height: 2.375rem;
                    line-height: 2.375rem;
                    text-align: center;
                    pointer-events: none;
                    color: #aaa;
                    margin-top: 4px;
                    width: 24px;
                    margin-left: 9px;
                }

                .form-control {
                    padding-left: 2.3rem;
                }
            }
            p {
                font-family: Roboto;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #242326;
                margin-top: 5px;
            }
            h3 {
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #8b7df2;
            }
            h4 {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                color: #81828c;
            }
        }
        .otp-holder {
            font-family: Roboto;
            text-align: center;
            p {
                margin-top: 8px;
                font-size: 14px;
                font-weight: 500;
            }
            h3 {
                font-size: 25px;
            }
            .primary-button {
                background-color: #8b7df2;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                border-radius: 4px;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #fff;
                border: none;
                width: 100%;
                height: 48px;
            }
            .otp-fields {
                padding-bottom: 25px;
                p {
                    margin-bottom: 20px;
                    margin-top: 8px;
                    font-weight: 500;
                    font-size: 14px;
                }
                input {
                    height: 48px;
                    border-radius: 0px;
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    color: #535259;
                    border: 1px solid #8b7df2;
                    &:focus {
                        border: 1px solid #8b7df2;
                        box-shadow: none;
                    }
                }
            }
        }
    }
    .elite-lang-select {
        display: flex;
        align-items: center;
        margin-top: 10px;
        div {
            width: 50%;
            height: 40px;
            background: #fff;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #535259;
            cursor: pointer;
            &:nth-child(2) {
                margin-left: 10px;
            }
            &:nth-child(3) {
                margin-left: 10px;
            }
        }
    }
}

@media (max-width: 767px) {
    .login-module {
        // overflow: hidden;
        background-image: none;
        background-color: #fff;
        .login-holder {
            width: 370px;
            background-color: #fff;
            box-shadow: none;
            .quotes {
                display: none;
            }
        }
    }
}

.button-color {
    background-color: #8b7df2 !important;
    color: #fff !important;
}
.cursor-pointer {
    cursor: pointer;
}
